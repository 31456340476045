<template>
  <div class="relative z-50 w-full h-full" aria-labelledby="slide-over-title" role="dialog" aria-modal="true">
    <transition name="fade" enter-active-class="ease-in-out duration-500" leave-active-class="ease-in-out duration-500"
      enter-from-class="opacity-0" enter-to-class="opacity-100" leave-from-class="opacity-100"
      leave-to-class="opacity-0">
      <div v-if="isVisibleDeviation" class="fixed inset-0 bg-gray-700 bg-opacity-80 transition-opacity"></div>
    </transition>
    <transition name="slide" enter-active-class="transform transition ease-in-out duration-500 sm:duration-700"
      enter-from-class="translate-x-full" enter-to-class="translate-x-0"
      leave-active-class="transform transition ease-in-out duration-500 sm:duration-700"
      leave-from-class="translate-x-0" leave-to-class="translate-x-full">
      <div v-if="isVisibleDeviation" class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
            <div class="pointer-events-auto relative w-screen max-w-md">
              <div class="absolute left-0 top-0 -ml-8 flex pr-2 pt-4 sm:-ml-10 sm:pr-4">
                <button @click="close()" type="button"
                  class="relative rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white">
                  <span class="absolute -inset-2.5"></span>
                  <span class="sr-only">Close panel</span>
                  <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
                    aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
              <div class="flex h-full flex-col overflow-y-scroll space-y-6 bg-gray-100 py-6 px-4 shadow-xl">
                <div>
                  <p>Afvigelse</p>
                  <p class="text-xl font-medium">Vælg afvigelsesårsag</p>
                </div>
                <div :class="{'opacity-50': activeDeviation }">
                  <p>Hovedårsag</p>
                  <div class="relative">
                    <select id="transport_type"
                      :disabled="activeDeviation"
                      :class="{'pointer-events-none': activeDeviation}"
                      class="block w-full appearance-none h-10 text-sm font-medium rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-200 focus:ring-2 focus:ring-inset focus:ring-secondary sm:text-sm sm:leading-6"
                      aria-labelledby="transport_type" v-model="selectedMainReason">
                      <option v-for="reason in deviationReasons" :key="reason.id" :value="reason.id">
                        {{ reason.name }}
                      </option>
                    </select>
                    <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-400">
                      <svg class="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M5.29289 7.29289C5.68342 6.90237 6.31658 6.90237 6.7071 7.29289L9.99999 10.5858L13.2929 7.29289C13.6834 6.90237 14.3166 6.90237 14.7071 7.29289C15.0976 7.68342 15.0976 8.31658 14.7071 8.70711L10.7071 12.7071C10.3166 13.0976 9.68341 13.0976 9.29289 12.7071L5.29289 8.70711C4.90237 8.31658 4.90237 7.68342 5.29289 7.29289Z"
                          fill="#6B7280" />
                      </svg>
                    </div>
                  </div>
                </div>
                <div :class="{'opacity-50': isSpecificationDisabled || activeDeviation }">
                  <p>Specifikation</p>
                  <div class="relative">
                    <select id="specification" v-model="selectedSpecification" 
                      :disabled="isSpecificationDisabled || activeDeviation"
                      :class="{'pointer-events-none': isSpecificationDisabled || activeDeviation}"
                      class="block w-full appearance-none h-10 text-sm font-medium rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-200 focus:ring-2 focus:ring-inset focus:ring-secondary sm:text-sm sm:leading-6">
                      <option v-for="spec in specifications" :key="spec.id" :value="spec.id">
                        {{ spec.addressName }}
                      </option>
                    </select>
                    <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-400">
                      <svg class="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M5.29289 7.29289C5.68342 6.90237 6.31658 6.90237 6.7071 7.29289L9.99999 10.5858L13.2929 7.29289C13.6834 6.90237 14.3166 6.90237 14.7071 7.29289C15.0976 7.68342 15.0976 8.31658 14.7071 8.70711L10.7071 12.7071C10.3166 13.0976 9.68341 13.0976 9.29289 12.7071L5.29289 8.70711C4.90237 8.31658 4.90237 7.68342 5.29289 7.29289Z"
                          fill="#6B7280" />
                      </svg>
                    </div>
                  </div>
                </div>
                <button type="submit" @click="submit()"
                  :disabled="isSubmitDisabled"
                  :class="{'opacity-20 pointer-events-none': isSubmitDisabled}"
                  class="flex w-full justify-center rounded-md bg-primary px-3 py-2 font-medium leading-6 text-white shadow-sm hover:bg-gray-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-secondary">
                  {{ activeDeviation ? 'Afslut afvigelse' : 'Angiv afvigelse' }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup>
import store from '@/store';
import { computed, defineProps, onMounted, watch, ref } from 'vue';

const props = defineProps({
  isVisibleDeviation: Boolean,
  bookingId: String,
})

const close = () => {
  store.commit('bookingStore/toggleDeviation')
}

onMounted(async () => {
  await store.dispatch('bookingStore/getDeviationReasons')
})


const activeDeviation = computed(() => store.state.bookingStore.activeDeviation)

const selectedMainReason = ref(null);
const selectedSpecification = ref(null);

watch(activeDeviation, () => {
  if (activeDeviation.value !== null) {
    console.log('activeDeviation.value', activeDeviation.value)

    selectedMainReason.value = activeDeviation.value.deviationReasonId;
    selectedSpecification.value = activeDeviation.value.deviationSpecificationId;
  }
});

const deviationReasons = computed(() => store.state.bookingStore.deviationReasons)

const specifications = computed(() => {
  const reason = deviationReasons.value.find(r => r.id === selectedMainReason.value);
  return reason ? reason.specifications : [];
});

watch(selectedMainReason, () => {
  if (activeDeviation.value === null) {
    selectedSpecification.value = null;
  }
});

const isSpecificationDisabled = computed(() => {
  return specifications.value.length === 0;
});

const isSubmitDisabled = computed(() => {
  if (!selectedMainReason.value) {
    return true; // Disable if no main reason is selected
  }
  if (specifications.value.length > 0 && !selectedSpecification.value) {
    return true; // Disable if specifications exist but none is selected
  }

  if (activeDeviation.value) {
    return false;
  }
  return false; // Enable if the above conditions are not met
});

const submit = async () => {
  if (!activeDeviation.value) {
    const payload = {
      bookingId: parseInt(props.bookingId),
      deviationReasonId: selectedMainReason.value,
      deviationSpecificationId: selectedSpecification.value,
      startTime: new Date(),
    };
    await store.dispatch('bookingStore/beginDeviation', payload);
  } else {
    const payload = {
      deviationId: activeDeviation.value.id,
      endTime: new Date(),
    };
    await store.dispatch('bookingStore/endDeviation', payload);
  }
  close();
}

</script>