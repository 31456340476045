import booking from '@/repositories/bookings/bookingRepository'
import apiState from '@/core/enums/apiStates'
import { Commit } from 'vuex'

const bookingStore = {
  namespaced: true,
  state: {
    bookingList: [],
    bookingListState: apiState.INIT,
    booking: {},
    bookingState: apiState.INIT,
    bookingRoute: {},
    bookingRouteState: apiState.INIT,
    washOpen: false,
    deviationOpen: false,
    trailerDetailsOpen: false,
    trailerOptions: [],
    actionState: undefined,
    deviationReasons: {},
    activeDeviation: null,
  },
  getters: {
    bookingState (state: any) {
      return state.bookingState
    },
    booking (state: any) {
      return state.booking
    },
    bookingRouteState (state: any) {
      return state.bookingRouteState
    },
    bookingRoute (state: any) {
      return state.bookingRoute
    },
    bookingListState (state: any) {
      return state.bookingListState
    },
    bookingList (state: any) {
      return state.bookingList
    },
    washOpen (state: any) {
      return state.washOpen
    },
    deviationOpen (state: any) {
      return state.deviationOpen
    },
    trailerDetailsOpen (state: any) {
      return state.trailerDetailsOpen
    },
    trailerOptions (state: any) {
      return state.trailerOptions
    },
    actionState (state: any) {
      return state.actionState
    },
    deviationReasons (state: any) {
      return state.deviationReasons;
    },
    activeDeviation (state: any) {
      return state.activeDeviation;
    }
  },
  mutations: {
    setbookingListState: (state: any, apiState: any) => {
      state.bookingListState = apiState
    },
    setbookingList: (state: any, response: any) => {
      const { data } = response
      state.bookingList = data
      state.bookingListState = apiState.LOADED
    },
    setbookingState: (state: any, apiState: any) => {
      state.bookingState = apiState
    },
    setbooking: (state: any, response: any) => {
      const { data } = response
      state.booking = data
      state.bookingState = apiState.LOADED
    },
    setbookingRouteState: (state: any, apiState: any) => {
      state.bookingState = apiState
    },
    setDeviationReasons: (state: any, response: any) => {
      console.log('setting deviation reasons to:', response)
      state.deviationReasons = response
    },
    setActiveDeviation (state: any, response: any) {
      state.activeDeviation = response
    },
    setbookingRoute: (state: any, response: any) => {
      const { data } = response
      state.bookingRoute = data
      state.bookingRouteState = apiState.LOADED
    },
    setTrailerOptions: (state: any, options: any) => {
      state.trailerOptions = options
    },
    toggleWashOpen (state: any) {
      state.washOpen = !state.washOpen
      console.log('state.washOpen: ' + state.washOpen)
    },
    toggleDeviation (state: any) {
      state.deviationOpen = !state.deviationOpen;
      console.log('state.deviationOpen: ' + state.deviationOpen)
    },
    toggleSetTrailer (state: any) {
      state.toggleSetTrailer = !state.toggleSetTrailer
      console.log('state.toggleSetTrailer', state.toggleSetTrailer)
    },
    setWash (state: any) {
      state.booking.washState = 1
      console.log('state.washOpen: ' + state.washOpen)
    },
    bookingDefrostState (state: any, response: any) {
      state.booking.defrostState = response
      console.log('state.defrostState: ' + state.booking.defrostState)
    },
    setBookingActionState (state: any, response: any) {
      state.actionState = response
      console.log('state.actionState: ' + state.actionState)
    },
    setBookingActionResponse (state: any) {
      if (state.actionState === 'PAUSE') {
        state.booking.isMonitoringPaused = true
      }
      if (state.actionState === 'RESUME') {
        state.booking.isMonitoringPaused = false
      }
      if (state.actionState === 'STOP' || state.actionState === 'CANCEL') {
        state.booking.status = 3
      }
      if (state.actionState === 'IMAGESTART') {
        state.booking.imageStartState = 1
      }
      if (state.actionState === 'IMAGEEND') {
        state.booking.imageEndState = 1
      }
    },
    setBookingTrailer(state: any, response: any) {
      state.booking.trailerName = response.name
      state.booking.trailerId = response.id
    }
  },
  actions: {
    async getBookingList ({ commit }: { commit: Commit }) {
      commit('setbookingListState', apiState.LOADING)
      try {
        const response = await booking.getBookingList()
        commit('setbookingList', response)
        console.log('getBookingList', response)
      } catch (error) {
        commit('setbookingListState', apiState.ERROR)
      }
    },
    async getBooking ({ commit }: { commit: Commit }, id: number) {
      commit('setbookingState', apiState.LOADING)
      try {
        const response = await booking.getBooking(id)
        commit('setbooking', response)
        console.log('getBooking', response)
      } catch (error) {
        commit('setbookingState', apiState.ERROR)
      }
    },
    async getBookingRoute ({ commit }: { commit: Commit }, dto: any) {
      commit('setbookingRouteState', apiState.LOADING)
      try {
        const response = await booking.getBookingRoute(dto.fromAddress, dto.toAddress)
        commit('setbookingRoute', response)
      } catch (error) {
        commit('setbookingRouteState', apiState.ERROR)
      }
    },
    async toggleWashOpen ({ commit }: { commit: Commit}) {
      commit('toggleWashOpen')
    },
    async setWash ({ commit }: { commit: Commit}) {
      commit('setWash')
    },
    async bookingDefrostState ({ commit }: { commit: Commit}, response: any) {
      commit('bookingDefrostState', response)
    },
    async setBookingActionState ({ commit }: { commit: Commit}, response: any) {
      commit('setBookingActionState', response)
    },
    async setBookingActionResponse ({ commit }: { commit: Commit}) {
      commit('setBookingActionResponse')
      commit('setBookingActionState', undefined)
    },
    async getDeviationReasons ({ commit }: { commit: Commit }) {
      try {
        const response = await booking.getDeviationReasons();
        if (response && response.data) {
          commit('setDeviationReasons', response.data);
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getActiveDeviation ({ commit }: { commit: Commit }, bookingId: number) {
      try {
        const response = await booking.getActiveDeviation(bookingId);
        if (response && response.data && response.data !== '') {
          commit('setActiveDeviation', response.data);
        }
        else {
          commit('setActiveDeviation', null);
        }
      } catch (error) {
        console.log(error);
      }
    },
    async beginDeviation ({ commit }: { commit: Commit }, dto: any) {
      try {
        console.log('dto payload', dto)
        const response = await booking.beginDeviation(dto.bookingId, dto.deviationReasonId, dto.startTime, dto.deviationSpecificationId);
        if (response && response.data) {
          commit('setActiveDeviation', response.data);
        }
      } catch (error) {
        console.log(error);
      }
    },
    async endDeviation ({ commit }: { commit: Commit }, dto: any) {
      try {
        const response = await booking.endDeviation(dto.deviationId, dto.endTime);
        if (response && response.data) {
          commit('setActiveDeviation', null);
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
  modules: {
  }
}

export default bookingStore
