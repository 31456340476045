<template>
  <div class="relative z-50 w-full h-full" aria-labelledby="slide-over-title" role="dialog" aria-modal="true">
    <transition name="fade" enter-active-class="ease-in-out duration-500" leave-active-class="ease-in-out duration-500"
      enter-from-class="opacity-0" enter-to-class="opacity-100" leave-from-class="opacity-100"
      leave-to-class="opacity-0">
      <div v-if="isVisible" class="fixed inset-0 bg-gray-700 bg-opacity-80 transition-opacity"></div>
    </transition>
    <transition name="slide" enter-active-class="transform transition ease-in-out duration-500 sm:duration-700"
      enter-from-class="translate-x-full" enter-to-class="translate-x-0"
      leave-active-class="transform transition ease-in-out duration-500 sm:duration-700"
      leave-from-class="translate-x-0" leave-to-class="translate-x-full">
      <div v-if="isVisible" class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
            <div class="pointer-events-auto relative w-screen max-w-md">
              <div class="absolute left-0 top-0 -ml-8 flex pr-2 pt-4 sm:-ml-10 sm:pr-4">
                <button @click="close()" type="button"
                  class="relative rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white">
                  <span class="absolute -inset-2.5"></span>
                  <span class="sr-only">Close panel</span>
                  <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
                    aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
              <div class="flex h-full flex-col overflow-y-scroll bg-gray-100 py-6 shadow-xl px-6 sm:px-6">
                <span class="text-gray-400 text-sm">TRAILER</span>
                <span class="text-primary font-medium text-lg pb-6">Skift trailer</span>
                <select id="trailer-select" v-model="selectedOption"
                  class="block w-full rounded-md border border-gray-300 p-2 text-base shadow-sm focus:border-primary focus:ring focus:ring-primary focus:ring-opacity-50">
                  <option v-for="option in options" :key="option.id" :value="option">
                    {{ option.label }}
                  </option>
                </select>
                <button @click="changeTrailer"
                  class="mt-2 flex w-full justify-center rounded-md bg-primary px-3 py-2 font-medium leading-6 text-white shadow-sm hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                  :class="{ 'opacity-20 pointer-events-none': isButtonDisabled }" :disabled="isButtonDisabled">
                  Skift trailer
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup lang="ts">
import bookingRepository from '@/repositories/bookings/bookingRepository';
import { computed, ref, watch } from 'vue';
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'

const props = defineProps({
  isVisible: Boolean
})

const store = useStore()
const route = useRoute()
const id = route.params.id
const booking = computed(() => store.getters['bookingStore/booking'])

console.log('bookin', booking.value)

const close = () => {
  store.commit('bookingStore/toggleSetTrailer')
}

const options = ref<Array<any>>([]);
const currentTrailer = ref();
const selectedOption = ref();

const isButtonDisabled = computed(() => {
  return !selectedOption.value || currentTrailer.value === selectedOption.value;
});

const fetchAvailableTrailers = async () => {
  try {
    const response = await bookingRepository.getAvailableTrailers();
    if (response && response.data) {
      options.value = response.data.map((trailer: any) => ({
        vehicleId: trailer.id,
        driverId: trailer.driverId,
        label: trailer.name
      }));
      store.commit('bookingStore/setTrailerOptions', options.value)
      currentTrailer.value = options.value.find(option => option.label === booking.value.trailerName)
      selectedOption.value = currentTrailer.value
    }
  } catch (error) {
    console.log(error)
  }
}

fetchAvailableTrailers()

watch(() => props.isVisible, (value) => {
  if (value) {
    currentTrailer.value = options.value.find(option => option.label === booking.value.trailerName)
    selectedOption.value = currentTrailer.value
  } else {
    console.log('close')
  }
})

async function changeTrailer () {
  const vehicleId = selectedOption.value.vehicleId
  const driverId = selectedOption.value.driverId
  try {
    const response = await bookingRepository.changeTrailerForDriver({ trailerId: vehicleId, driverId: driverId, bookingId: booking.value.id, time: new Date() })
    store.commit('bookingStore/setBookingTrailer', { name: response.data.name, id: vehicleId })
    currentTrailer.value = options.value.find(x => x.vehicleId === response.data.id)
    close()
  } catch (error) {
    console.log(error)
  }
}

</script>
