<template>
  <li>
    <router-link :to="getRoute()" class="flex items-center bg-white text-sm text-gray-400">
      <div class="grow px-6 py-5">
        <h1 class="text-xl font-medium text-secondary">{{ title }}</h1>
        <div class="text-sm text-gray-400">{{ subTitle }}</div>
      </div>
      <div v-if="messages && messages > 0" class="flex bg-primary items-center justify-center -right-2 -top-2 text-[10px] text-white h-5 w-5 rounded-full ring-2 ring-white">
        {{ messages }}+
      </div>
      <div class="shrink-0 px-6">
        <svg width="9" height="40" viewBox="0 0 9 40" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8.02899 20.4773L2.56306 25.9432C2.29944 26.2068 1.87206 26.2068 1.60847 25.9432L0.97096 25.3057C0.707794 25.0425 0.707288 24.616 0.969835 24.3522L5.30168 20L0.969835 15.6478C0.707288 15.384 0.707794 14.9575 0.97096 14.6943L1.60847 14.0568C1.87208 13.7932 2.29947 13.7932 2.56306 14.0568L8.02896 19.5227C8.29257 19.7863 8.29257 20.2137 8.02899 20.4773Z" fill="#E5E7EB"/>
          </svg>
      </div>
    </router-link>
  </li>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'listItemComponent',
  components: { },
  props: {
    title: String,
    subTitle: String,
    route: String || undefined,
    messages: Number || undefined
  },
  methods: {
    getRoute () : string {
      if (this.route) {
        return this.route
      }
      return ''
    }
  }
})
</script>
