<template>
  <div class="relative z-50 w-full h-full" aria-labelledby="slide-over-title" role="dialog" aria-modal="true">
    <transition name="fade" enter-active-class="ease-in-out duration-500" leave-active-class="ease-in-out duration-500"
      enter-from-class="opacity-0" enter-to-class="opacity-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
      <div v-if="isVisible" class="fixed inset-0 bg-gray-700 bg-opacity-80 transition-opacity"></div>
    </transition>
    <transition name="slide" enter-active-class="transform transition ease-in-out duration-500 sm:duration-700"
      enter-from-class="translate-x-full" enter-to-class="translate-x-0"
      leave-active-class="transform transition ease-in-out duration-500 sm:duration-700" leave-from-class="translate-x-0"
      leave-to-class="translate-x-full">
      <div v-if="isVisible" class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
            <div class="pointer-events-auto relative w-screen max-w-md">
              <div class="absolute left-0 top-0 -ml-8 flex pr-2 pt-4 sm:-ml-10 sm:pr-4">
                <button @click="close()" type="button"
                  class="relative rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white">
                  <span class="absolute -inset-2.5"></span>
                  <span class="sr-only">Close panel</span>
                  <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
                    aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>

              <div class="flex h-full flex-col overflow-y-scroll bg-gray-100 py-6 shadow-xl">
                <div class="flex flex-col gap-4 px-6 sm:px-6">

                  <div class="flex grow flex-col">
                    <div class="text-base font-medium leading-5 text-gray-800">
                      <div class="flex justify-between items-center">
                        <div class="text-sm text-gray-400">
                          STOP
                        </div>
                        <!-- <span class="float-right">
                          <div @click.stop.prevent="handleSelect()" class="z-50">
                            <svg width="30" height="33" viewBox="0 0 25 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <g clip-path="url(#clip0_479_1176)">
                                <path d="M12.5313 20.25C13.3931 20.25 14.0938 19.5493 14.0938 18.6875H10.9688C10.9688 19.5518 11.6695 20.25 12.5313 20.25ZM17.7901 16.5952C17.3189 16.0874 16.4351 15.3257 16.4351 12.8281C16.4351 10.9312 15.1046 9.4126 13.3126 9.03906V8.53125C13.3126 8.09912 12.9634 7.75 12.5313 7.75C12.0992 7.75 11.7501 8.09912 11.7501 8.53125V9.03906C9.95807 9.4126 8.62751 10.9312 8.62751 12.8281C8.62751 15.3257 7.74372 16.0874 7.27253 16.5952C7.12604 16.7539 7.06012 16.9419 7.06257 17.125C7.06501 17.5254 7.37995 17.9062 7.84626 17.9062H17.2164C17.6827 17.9062 17.9976 17.5254 18.0001 17.125C18.0025 16.9395 17.9366 16.7515 17.7901 16.5952Z" fill="#858E97"/>
                              </g>
                            </svg>
                            <div ref="dropdownRef" @click.stop.prevent v-if="showSelect" class="absolute right-3 z-40 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
                              <div class="text-gray-700 rounded-md block text-sm font-medium" role="none">
                                <div class="bg-gray-300 px-4 py-3 rounded-t-md">
                                  {{ isSelectingReason ? 'Vælg årsag til de-aktivering' : 'Alarm for sikker rute'}}
                                </div>
                                <div v-if="!isSelectingReason">
                                  <div class="flex items-center space-x-3 px-4 py-3">
                                    <label class="relative inline-flex items-center cursor-pointer">
                                      <input type="checkbox" class="sr-only peer" :checked="isActiveAlarm">
                                      <div
                                        @click.prevent="toggleIsActiveAlarm()" 
                                        class="w-12 h-7 mr-2 rounded-full peer-focus:outline-none peer-focus:ring-4 transition duration-300 ease-in-out" :class="{'bg-[#FF471F]': !isActiveAlarm, 'bg-[#E3E3E3]': isActiveAlarm}">
                                        <div class="absolute left-1 top-1 bg-white w-5 h-5 rounded-full flex items-center justify-center transition transform duration-300 ease-in-out"
                                          :class="{'translate-x-5': !isActiveAlarm, 'translate-x-0': isActiveAlarm}">
                                          <svg v-show="isActiveAlarm" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 11 13" class="h-3 w-3">
                                            <path d="M5.46881 12.5C6.33063 12.5 7.03131 11.7993 7.03131 10.9375H3.90631C3.90631 11.8018 4.607 12.5 5.46881 12.5ZM10.7276 8.84521C10.2564 8.3374 9.37262 7.57568 9.37262 5.07812C9.37262 3.18115 8.04206 1.6626 6.25006 1.28906V0.78125C6.25006 0.349121 5.90094 0 5.46881 0C5.03669 0 4.68756 0.349121 4.68756 0.78125V1.28906C2.89557 1.6626 1.56501 3.18115 1.56501 5.07812C1.56501 7.57568 0.681218 8.3374 0.210026 8.84521C0.0635419 9.00391 -0.00237608 9.19189 6.53255e-05 9.375C0.00250673 9.77539 0.317448 10.1562 0.783757 10.1562H10.1539C10.6202 10.1562 10.9351 9.77539 10.9376 9.375C10.94 9.18945 10.8741 9.00146 10.7276 8.84521Z" fill="#858E97"/>
                                          </svg>
                                          <svg v-show="!isActiveAlarm" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 11 13" class="h-3 w-3">
                                            <path d="M15.4736 11.1841L13.2617 9.47273C13.2666 9.43855 13.2812 9.40682 13.2812 9.37264C13.2837 9.18709 13.2178 8.99911 13.0713 8.84287C12.6001 8.33507 11.7163 7.57337 11.7163 5.07587C11.7163 3.17895 10.3858 1.66043 8.59385 1.28691V0.781547C8.59385 0.349429 8.24474 0.000316764 7.81262 0.000316764C7.3805 0.000316764 7.03139 0.349429 7.03139 0.781547V1.28935C6.04753 1.49442 5.2077 2.0486 4.64864 2.81763L1.11113 0.0833225C0.940234 -0.0509515 0.693659 -0.019214 0.561826 0.15168L0.0833225 0.76934C-0.0509515 0.937793 -0.019214 1.18437 0.15168 1.3162L14.5165 12.417C14.6874 12.5488 14.9316 12.5195 15.0659 12.3486L15.5444 11.731C15.6762 11.5601 15.6469 11.3159 15.476 11.1817L15.4736 11.1841ZM3.83811 6.1403C3.62815 7.80041 2.94946 8.41807 2.55396 8.84531C2.40748 9.00399 2.34157 9.19198 2.34401 9.37508C2.34645 9.77546 2.66138 10.1563 3.12768 10.1563H9.03329L3.83811 6.1403ZM7.81262 12.5C8.67441 12.5 9.37508 11.7993 9.37508 10.9375H6.25016C6.25016 11.8018 6.95082 12.5 7.81262 12.5Z" fill="#FF471F"/>
                                          </svg>
                                        </div>
                                      </div>
                                    </label>
                                    {{ isActiveAlarm ? 'Alarmering aktiveret' : 'Alarmering deaktiveret'}}
                                  </div>
                                </div>
                                <div v-else>
                                  <div class="px-2 py-2">
                                    <div class="">
                                      <template v-for="(reason, index) in deviationReasons" :key="index">
                                        <div class="p-2 hover:bg-gray-200 cursor-pointer" @click="selectReason(reason)">
                                          {{ reason.text }}
                                        </div>
                                        <hr v-if="index < deviationReasons.length - 1" class="border-t border-gray-300 mx-0">
                                      </template>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </span> -->
                      </div>
                      {{ details.fromAddressStop }}
                    </div>
                  </div>

                  <div>
                    <label for="transport_type" class="block text-sm font-medium leading-6 text-secondary">Navn</label>
                    <div v-if="hasInvalidAddress" class="relative">
                      <select id="transport_type"
                        class="block w-full appearance-none text-sm font-medium rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-200 focus:ring-2 focus:ring-inset focus:ring-secondary sm:text-sm sm:leading-6"
                        aria-labelledby="transport_type" v-model="selectedOptionStop">
                        <option value="" disabled selected>Vælg et stop</option>
                        <option v-for="location of systemLocations" :key="location.id" :value="location.id">{{
                          location.name }}</option>
                      </select>
                      <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-400">
                        <svg class="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M5.29289 7.29289C5.68342 6.90237 6.31658 6.90237 6.7071 7.29289L9.99999 10.5858L13.2929 7.29289C13.6834 6.90237 14.3166 6.90237 14.7071 7.29289C15.0976 7.68342 15.0976 8.31658 14.7071 8.70711L10.7071 12.7071C10.3166 13.0976 9.68341 13.0976 9.29289 12.7071L5.29289 8.70711C4.90237 8.31658 4.90237 7.68342 5.29289 7.29289Z"
                            fill="#6B7280" />
                        </svg>
                      </div>
                    </div>
                    <div v-else>
                      {{ details.fromAddressStop }}
                    </div>
                  </div>
                  <div v-if="selectedOptionStop">
                    <button type="submit" @click="saveOptionStop(selectedOptionStop)"
                      class="flex w-full justify-center rounded-md bg-blue-400 px-3 py-2 font-medium leading-6 text-white shadow-sm hover:bg-gray-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-secondary">
                      Gem ændring
                    </button>
                  </div>

                  <div>
                    <div class="flex grow flex-col">
                      <div class="text-sm text-gray-400">Adresse</div>
                      <div class="text-base font-medium leading-5 text-gray-800">
                        {{ details.fromAddressStreet }}
                      </div>
                    </div>
                  </div>

                  <div>
                    <div class="flex grow flex-col">
                      <div class="text-sm text-gray-400">By</div>
                      <div class="text-base font-medium leading-5 text-gray-800">
                        {{ details.fromAddressCity }}
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="!hasInvalidAddress" class="relative mt-6 flex-1 bg-white px-4 sm:px-6">
                  <div class="relative my-3" :class="{'opacity-20 pointer-events-none' : details.canStart === false || (details.stopStarted && !details.stopEnded)}">
                    <select id="transport_type"
                      :disabled="activeDeviation"
                      :class="{'opacity-20 pointer-events-none': activeDeviation}"
                      class="block w-full appearance-none text-sm font-medium rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-200 focus:ring-2 focus:ring-inset focus:ring-secondary sm:text-sm sm:leading-6"
                      aria-labelledby="transport_type" v-model="selectedOptionStopType">
                      <option value="" disabled selected>Vælg stop type</option>
                      <option value="1">Afhentning</option>
                      <option value="2">Aflevering</option>
                      <option value="3">Intet gods</option>
                      <!-- Kun muligt ved predefineret stop type delivery/aflevering -->
                      <option v-if="details.reL_StopType == 2" value="4">Fjern stop</option>
                    </select>
                    <div 
                      :class="{'opacity-20 pointer-events-none': activeDeviation}"
                      class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-400">
                      <svg class="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M5.29289 7.29289C5.68342 6.90237 6.31658 6.90237 6.7071 7.29289L9.99999 10.5858L13.2929 7.29289C13.6834 6.90237 14.3166 6.90237 14.7071 7.29289C15.0976 7.68342 15.0976 8.31658 14.7071 8.70711L10.7071 12.7071C10.3166 13.0976 9.68341 13.0976 9.29289 12.7071L5.29289 8.70711C4.90237 8.31658 4.90237 7.68342 5.29289 7.29289Z"
                          fill="#6B7280" />
                      </svg>
                    </div>
                  </div>
                  <div>
                    <div v-if="selectedOptionStopType === '1'" class="px-2">
                      <div :class="{'opacity-20 pointer-events-none': details.stopStarted === false && details.stopDone === false}">
                        <div class="flex items-center mb-4">
                          <div class="grow">
                            <h1 class="text-xl font-medium text-primary">Gods</h1>
                          </div>
                        </div>
                        <ul role="list" class="divide-y divide-gray-200 border-y border-gray-200 p-0">
                          <li class="m-0 flex items-center p-0">
                            <a class="flex w-full gap-x-4 no-underline py-4 items-center">
                              <div class="grow">
                                <div class="text-base font-medium leading-5 text-gray-800">Paller ({{ details.quantity }})</div>
                              </div>
                              <div class="flex items-center shrink-0 space-x-2">
                                <button
                                  @click="decrementQuantity()"
                                  class="flex w-8 h-8 rounded-full border border-secondary items-center justify-center hover:shadow-md">
                                  <svg class="fill-secondary" width="10" height="2" viewBox="0 0 10 2" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                      d="M6 0H9C9.55228 0 10 0.447716 10 1C10 1.55229 9.55228 2 9 2H6L4 2H1C0.447715 2 0 1.55228 0 1C0 0.447715 0.447715 0 1 0H4H6Z" />
                                  </svg>
                                </button>
                                <div>{{ quantity }}</div>
                                <button
                                  @click="incrementQuantity()"
                                  class="flex w-8 h-8 rounded-full border border-secondary items-center justify-center hover:shadow-md">
                                  <svg class="fill-secondary" width="20" height="20" viewBox="0 0 20 20" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                      d="M10 5C10.5523 5 11 5.44772 11 6V9L14 9C14.5523 9 15 9.44772 15 10C15 10.5523 14.5523 11 14 11H11V14C11 14.5523 10.5523 15 10 15C9.44771 15 9 14.5523 9 14V11H6C5.44772 11 5 10.5523 5 10C5 9.44771 5.44772 9 6 9L9 9V6C9 5.44772 9.44771 5 10 5Z" />
                                  </svg>
                                </button>
                              </div>
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div class="py-4">
                        <button type="submit"
                          class="flex w-full justify-center rounded-md bg-blue-400 px-3 py-2 font-medium leading-6 text-white shadow-sm hover:bg-gray-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-secondary"
                          :class="{'opacity-20 pointer-events-none' : details.canStart === false || (quantity === '' && details.stopStarted)}"
                          @click="handleStopToggleAction()">
                          {{ details.stopStarted === false && details.stopDone === false ? 'Påbegynd afhentning' : 'Marker som afhentet' }}
                        </button>
                        <label class="text-sm text-center" v-if="!details.canStart">
                          Start eller afslut venligst det forrige stop i rækkefølgen, før du fortsætter med dette.
                        </label>
                      </div>
                    </div>
                    <div v-if="selectedOptionStopType === '2'" class="px-2">
                      <div :class="{'opacity-20 pointer-events-none': details.stopStarted === false && details.stopDone === false}">
                        <div class="flex items-center mb-4">
                          <div class="grow">
                            <h1 class="text-xl font-medium text-primary">Gods</h1>
                          </div>
                        </div>
                        <ul role="list" class="divide-y divide-gray-200 border-y border-gray-200 p-0">
                          <li class="m-0 flex items-center p-0">
                            <a class="flex w-full gap-x-4 no-underline py-4 items-center">
                              <div class="grow">
                                <div class="text-base font-medium leading-5 text-gray-800">Paller</div>
                              </div>
                              <div class="flex items-center shrink-0 space-x-2">
                                <button
                                  @click="decrementQuantity()"
                                  class="flex w-8 h-8 rounded-full border border-secondary items-center justify-center hover:shadow-md">
                                  <svg class="fill-secondary" width="10" height="2" viewBox="0 0 10 2" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                      d="M6 0H9C9.55228 0 10 0.447716 10 1C10 1.55229 9.55228 2 9 2H6L4 2H1C0.447715 2 0 1.55228 0 1C0 0.447715 0.447715 0 1 0H4H6Z" />
                                  </svg>
                                </button>
                                <div>{{ quantity }}</div>
                                <button
                                  @click="incrementQuantity()"
                                  class="flex w-8 h-8 rounded-full border border-secondary items-center justify-center hover:shadow-md">
                                  <svg class="fill-secondary" width="20" height="20" viewBox="0 0 20 20" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                      d="M10 5C10.5523 5 11 5.44772 11 6V9L14 9C14.5523 9 15 9.44772 15 10C15 10.5523 14.5523 11 14 11H11V14C11 14.5523 10.5523 15 10 15C9.44771 15 9 14.5523 9 14V11H6C5.44772 11 5 10.5523 5 10C5 9.44771 5.44772 9 6 9L9 9V6C9 5.44772 9.44771 5 10 5Z" />
                                  </svg>
                                </button>
                              </div>
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div class="py-4">
                        <button 
                          v-if="!activeDeviation"
                          type="submit"
                          class="flex w-full justify-center rounded-md bg-blue-400 px-3 py-2 font-medium leading-6 text-white shadow-sm hover:bg-gray-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-secondary"
                          :class="{'opacity-20 pointer-events-none' : details.canStart === false || (quantity === '' && details.stopStarted)}"
                          @click="handleStopToggleAction()">
                          {{ details.stopStarted === false && details.stopDone === false ? 'Påbegynd aflevering' : 'Marker som afleveret' }}
                        </button>
                        <button type="submit"
                          v-if="activeDeviation && details.canStart"
                          class="flex w-full justify-center rounded-md bg-red-400 px-3 py-2 font-medium leading-6 text-white shadow-sm hover:bg-gray-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-secondary"
                          @click="endDeviation()">
                          Afslut først den aktive afvigelse
                        </button>
                        <label class="text-sm text-center" v-if="!details.canStart">
                          Start eller afslut venligst det forrige stop i rækkefølgen, før du fortsætter med dette.
                        </label>
                      </div>
                    </div>
                    <div v-if="selectedOptionStopType === '3'" class="px-2">
                      <div class="py-4">
                        <button type="submit"
                          @click="markAsNoCargo()"
                          class="flex w-full justify-center rounded-md bg-blue-400 px-3 py-2 font-medium leading-6 text-white shadow-sm hover:bg-gray-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-secondary">
                          Marker som intet gods
                        </button>
                      </div>
                    </div>
                    <div v-if="selectedOptionStopType === '4'" class="px-2">
                      <button type="submit"
                          @click="removeStop()"
                          class="flex w-full justify-center rounded-md bg-blue-400 px-3 py-2 font-medium leading-6 text-white shadow-sm hover:bg-gray-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-secondary">
                          Fjern stop
                        </button>
                    </div>
                  </div>
                </div>

                <div v-if="!hasInvalidAddress" class="relative mt-6 flex-1 bg-white px-4 sm:px-6 py-5">
                  <div>
                    <div class="flex items-center mb-4">
                      <div class="grow">
                        <h1 class="text-xl font-medium text-primary">Bemærkninger til stop</h1>
                      </div>
                    </div>
                    <div class="divide-y p-0">
                      <div class="m-0 flex items-center p-0" v-if="details.memoInternal">
                        <span class="flex w-full gap-x-4 no-underline py-1.5">
                          <div class="grid grow">
                            <div class="text-base font-medium leading-5 text-gray-800"></div>
                            <div class="text-base font-medium leading-5 truncate text-gray-800">
                              {{ details.memoInternal }}
                            </div>
                          </div>
                        </span>
                      </div>
                      <div class="m-0 flex items-center p-0" v-if="details.memoDriver">
                        <span class="flex w-full gap-x-4 no-underline py-1.5">
                          <div class="grid grow">
                            <div class="text-base font-medium leading-5 text-gray-800"></div>
                            <div class="text-base font-medium leading-5 truncate text-gray-800">
                              {{ details.memoDriver }}
                            </div>
                          </div>
                        </span>
                      </div>
                      <div class="m-0 flex items-center p-0" v-if="details.memoExternal">
                        <span class="flex w-full gap-x-4 no-underline py-1.5">
                          <div class="grid grow">
                            <div class="text-base font-medium leading-5 text-gray-800"></div>
                            <div class="text-base font-medium leading-5 truncate text-gray-800">
                              {{ details.memoExternal }}
                            </div>
                          </div>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup lang="ts">
import bookingRepository from '@/repositories/bookings/bookingRepository'
import stopRepository from '@/repositories/bookings/stopRepository'
import { Ref, computed, onMounted, onUnmounted, ref, watch, watchEffect } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'

const store = useStore()
const route = useRoute()
const id = route.params.id

const props = defineProps({
  isVisible: Boolean
})

const isStopServiceFunctionOpen = ref(false)

const close = () => {
  store.dispatch('stopStore/toggleDetails')
}

const details = computed(() => store.getters['stopStore/getDetails'])
const activeDeviation = computed(() => store.state.bookingStore.activeDeviation)

const systemLocations = ref([])
const selectedOptionStop = ref('')
const selectedOptionStopType = ref('')

const saveOptionStop = async (locationId: number) => {
  try {
    const response = await stopRepository.updateStopAddress(details.value.id, new Date(), locationId)
    if (response.data) {
      store.commit('stopStore/setDetails', response.data)
      store.dispatch('bookingStore/getBooking', id)
      selectedOptionStop.value = ''
    }
  } catch (error) {
    console.log('error updating address', error)
  }
}

const handleStopToggleAction = () => {
  if (details.value.stopStarted === false && details.value.stopDone === false) {
    startStop(details.value.id)
  } else {
    endStop(details.value.id, quantity.value as number)
  }
}

const startStop = async (stopId: number) => {
  try {
    const response = await stopRepository.startStop(stopId, new Date(), selectedOptionStopType.value)
    if (response.data) {
      store.commit('stopStore/setDetails', response.data)
      store.dispatch('bookingStore/getBooking', id)
    }
  } catch (error) {
    console.log('error toggling stop', error)
  }
}

const endStop = async (stopId: number, updatedQuantity: number) => {
  try {
    const response = await stopRepository.endStop(stopId, new Date(), updatedQuantity)
    if (response.data) {
      store.commit('stopStore/setDetails', response.data)
      store.dispatch('bookingStore/getBooking', id)
      store.commit('stopStore/toggleDetailsOpen')
    }
  } catch (error) {
    console.log('error toggling stop', error)
  }
}

const markAsNoCargo = async () => {
  try {
    const response = await stopRepository.markAsNoCargo(details.value.id, new Date())
    if (response.data) {
      store.commit('stopStore/setDetails', response.data)
      store.dispatch('bookingStore/getBooking', id)
      store.commit('stopStore/toggleDetailsOpen')
    }
  } catch (error) {
    console.log('error toggling stop', error)
  }
}

const removeStop = async () => {
  try {
    const response = await stopRepository.removeStop(details.value.id)
    if (response && response.data) {
      store.dispatch('bookingStore/getBooking', id)
      store.commit('stopStore/toggleDetailsOpen')
    }
  } catch (error) {
    console.log('error removing stop', error)
  }
}

const hasInvalidAddress = computed(() => details.value.fromAddressStop === 'STOP' || details.value.fromAddressStop === '')

const getSystemLocations = async () => {
  const response = await bookingRepository.getSystemLocations()
  if (response.data) {
    systemLocations.value = response.data
  }
}

const quantity = ref<string | number>('')

const incrementQuantity = () => {
  if (quantity.value === '') {
    quantity.value = 1
  } else {
    quantity.value = (typeof quantity.value === 'number' ? quantity.value : 0) + 1
  }
}

const decrementQuantity = () => {
  if (typeof quantity.value === 'number' && quantity.value > 0) {
    quantity.value -= 1
  }
}

onMounted(async () => {
  getSystemLocations()
  document.addEventListener('click', handleClickOutside);
})

onUnmounted(() => {
  document.removeEventListener('click', handleClickOutside);
});

watchEffect(() => {
  if (details.value.reL_StopType) {
    selectedOptionStopType.value = details.value.reL_StopType.toString()
  }
})

watch(details, (newDetails, oldDetails) => {
  quantity.value = ''
})

const toggleServiceFunctions = async (type: string) => {
  store.commit('stopStore/setServiceFunctionType', type)
  store.commit('stopStore/toggleServiceFunctions')
  showSelect.value = false
}

const isServiceFunctionsOpen = computed(() => store.getters['stopStore/serviceFunctionsOpen'])

const dropdownRef: Ref<HTMLElement | null> = ref(null);
const handleClickOutside = (event: MouseEvent) => {
  if (dropdownRef.value && !dropdownRef.value.contains(event.target as Node)) {
    showSelect.value = false;
  }
};

const isActiveAlarm = ref(true)
const isSelectingReason = ref(false)
const deviationReasons = ref([
  { text: "Test" },
])

if (details.value.toAddressName === "STOP") {
  deviationReasons.value.unshift({ text: "Ukendt destination" })
}

const showSelect = ref<boolean>(false);
const handleSelect = () => {
  showSelect.value = !showSelect.value
  isSelectingReason.value = false;
};

const endDeviation = async () => {
  const payload = {
    deviationId: activeDeviation.value.id,
    endTime: new Date(),
  };
  await store.dispatch('bookingStore/endDeviation', payload);
}

</script>
