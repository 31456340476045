<template>
  <div class="relative z-50 w-full h-full" aria-labelledby="slide-over-title" role="dialog" aria-modal="true">
    <transition name="fade" enter-active-class="ease-in-out duration-500" leave-active-class="ease-in-out duration-500"
      enter-from-class="opacity-0" enter-to-class="opacity-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
      <div v-if="isVisible" class="fixed inset-0 bg-gray-700 bg-opacity-80 transition-opacity"></div>
    </transition>
    <transition name="slide" enter-active-class="transform transition ease-in-out duration-500 sm:duration-700"
      enter-from-class="translate-x-full" enter-to-class="translate-x-0"
      leave-active-class="transform transition ease-in-out duration-500 sm:duration-700" leave-from-class="translate-x-0"
      leave-to-class="translate-x-full">
      <div v-if="isVisible" class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
            <div class="pointer-events-auto relative w-screen max-w-md">
              <div class="absolute left-0 top-0 -ml-8 flex pr-2 pt-4 sm:-ml-10 sm:pr-4">
                <button @click="close()" type="button"
                  class="relative rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white">
                  <span class="absolute -inset-2.5"></span>
                  <span class="sr-only">Close panel</span>
                  <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
                    aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>

              <div class="flex h-full flex-col overflow-y-scroll bg-gray-100 py-6 shadow-xl">
                <div class="flex flex-col gap-4 px-6 sm:px-6">
                  <div>
                    <div class="flex grow flex-col">
                      <div class="text-sm text-gray-400">Booking</div>
                      <div class="text-base font-medium leading-5 text-gray-800">
                        {{ details.bookingTitle }}</div>
                    </div>
                  </div>
                </div>

                <div class="flex h-full flex-col overflow-y-scroll bg-gray-100 py-6 shadow-xl px-6 sm:px-6">
                  <span class="text-gray-400 text-sm">Handling</span>
                  <div @click="triggerFileInput()"
                       v-if="actionHasImage"
                       class="p-4 border-dashed border-2 h-36 border-gray-300 rounded flex flex-col items-center justify-center relative overflow-visible mb-4">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M6.827 6.175A2.31 2.31 0 0 1 5.186 7.23c-.38.054-.757.112-1.134.175C2.999 7.58 2.25 8.507 2.25 9.574V18a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9.574c0-1.067-.75-1.994-1.802-2.169a47.865 47.865 0 0 0-1.134-.175 2.31 2.31 0 0 1-1.64-1.055l-.822-1.316a2.192 2.192 0 0 0-1.736-1.039 48.774 48.774 0 0 0-5.232 0 2.192 2.192 0 0 0-1.736 1.039l-.821 1.316Z" />
                      <path stroke-linecap="round" stroke-linejoin="round" d="M16.5 12.75a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0ZM18.75 10.5h.008v.008h-.008V10.5Z" />
                    </svg>
                    <span v-if="!selectedFile" class="mt-2 text-gray-700 text-sm w-36 text-center">
                      Vælg eller tag evt. billede til dokumentation
                    </span>
                    <img v-if="imageUrl" :src="imageUrl" class="absolute top-0 left-0 w-full h-full object-cover rounded" />
                    <img v-if="imageUrl" @click.stop="removeImage" :src="imageDeleteIcon" class="absolute -top-3 -right-3 cursor-pointer" />
                    <input type="file" ref="fileInput" @change="handleFileChange" accept="image/*" class="hidden" capture="environment" />
                  </div>
                  <div class="py-3">
                    <button type="submit"
                            @click="start()"
                            :disabled="actionHasImage && (!selectedFile || !imageReference)"
                            :class="{ 'opacity-20 pointer-events-none':actionHasImage && (!selectedFile || !imageReference) }"
                            class="flex w-full justify-center rounded-md bg-blue-400 px-3 py-2 font-medium leading-6 text-white shadow-sm hover:bg-gray-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-secondary">
                      {{ buttonText }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup lang="ts">
import bookingRepository from '@/repositories/bookings/bookingRepository'
import { computed, ref } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import imageDeleteIcon from '@/assets/icons/image-delete-icon.svg'

const store = useStore()
const route = useRoute()
const id = route.params.id
const imageReference = ref<string | ArrayBuffer | null | undefined>(null)
const selectedFile = ref<File | null>(null)
const fileInput = ref<File | null>(null)

const props = defineProps({
  isVisible: Boolean
})

const close = () => {
  store.dispatch('bookingStore/setBookingActionState', undefined)
}

const details = computed(() => store.getters['bookingStore/booking'])
const actionState = computed(() => store.getters['bookingStore/actionState'])

const buttonText = computed(() => {
  if (!actionState.value) {
    return 'Indlæser'
  }

  if (actionState.value === 'IMAGESTART' || actionState.value === 'IMAGEEND') {
    return 'Tilføj billede'
  }

  if (actionState.value === 'RESUME') {
    return 'Stop pause'
  }

  if (actionState.value === 'RESUME') {
    return 'Stop pause'
  }

  // Triggered when stop is started but not done
  if (actionState.value === 'STOP') {
    return 'Afslut tur - intet gods'
  }

  // Triggered when no stop is started but not done
  if (actionState.value === 'CANCEL') {
    return 'Afslut tur'
  }

  return 'Start pause'
})

const start = async () => {
  if (details.value.status === 3) {
    return
  }

  if (actionState.value === 'PAUSE') {
    const response = await bookingRepository.pauseTemperature(id, { bookingId: id, time: new Date(), user: '-' })
    if (response.data) {
      store.commit('bookingStore/setBookingActionResponse')
      store.dispatch('bookingStore/setBookingActionState', undefined)
    }
  }
  if (actionState.value === 'RESUME') {
    const response = await bookingRepository.resumeTemperature(id, { bookingId: id, time: new Date(), user: '-' })
    if (response.data) {
      store.commit('bookingStore/setBookingActionResponse')
      store.dispatch('bookingStore/setBookingActionState', undefined)
    }
  }
  if (actionState.value === 'CANCEL') {
    const response = await bookingRepository.cancelBooking(id, { bookingId: id, time: new Date(), user: '-' })
    if (response.data) {
      store.commit('bookingStore/setBookingActionResponse')
      store.dispatch('bookingStore/setBookingActionState', undefined)
    }
  }
  if (actionState.value === 'STOP') {
    const response = await bookingRepository.stopBooking(id, { bookingId: id, time: new Date(), user: '-' })
    if (response.data) {
      store.commit('bookingStore/setBookingActionResponse')
      store.dispatch('bookingStore/setBookingActionState', undefined)
    }
  }
  if (actionState.value === 'IMAGESTART' || actionState.value === 'IMAGEEND') {
    const response = await bookingRepository.addImage(id, { bookingId: id, time: new Date(), user: '-', imageReference: imageReference.value, actionState: actionState.value })
    if (response.data) {
      store.commit('bookingStore/setBookingActionResponse')
      store.dispatch('bookingStore/setBookingActionState', undefined)
    }
  }
}

const actionHasImage = computed(() => {
  if (!actionState.value) {
    return false
  }

  if (actionState.value === 'IMAGESTART' || actionState.value === 'IMAGEEND') {
    return true
  }
  return false
})

const triggerFileInput = () => {
  if (fileInput.value !== null) {
    fileInput.value.click()
  }
}

const imageUrl = computed(() => {
  return selectedFile.value ? URL.createObjectURL(selectedFile.value) : null
})

const removeImage = () => {
  selectedFile.value = null
  imageReference.value = null
  if (fileInput.value) {
    fileInput.value.value = ''
  }
}

const handleFileChange = (event: Event) => {
  const files = (event.target as HTMLInputElement).files
  if (files && files.length > 0) {
    const file = files[0]
    selectedFile.value = file
    const reader = new FileReader()
    reader.onload = (e) => {
      imageReference.value = e.target?.result
    }
    reader.readAsDataURL(file)
  }
}
</script>
