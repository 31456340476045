export const formatToTime = (dateString: string): string => {
  const date = new Date(dateString)
  let hours = date.getHours().toString()
  let minutes = date.getMinutes().toString()

  // Ensuring two digits for hours and minutes
  hours = hours.length < 2 ? '0' + hours : hours
  minutes = minutes.length < 2 ? '0' + minutes : minutes

  return `${hours}:${minutes}`
}
