import { createStore } from 'vuex'
import bookingStore from './bookings/bookingStore'
import stopStore from './bookings/stopStore'
import logStore from './bookings/logStore'
import defrostStore from './bookings/defrostStore'

export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    bookingStore,
    stopStore,
    logStore,
    defrostStore
  }
})
