<template>
  <!-- <div id="map" class="h-full w-full"></div> -->
  <LeafletMap />
</template>

<script setup lang="ts">
import { onMounted, ref, watchEffect } from 'vue'
import Map from 'ol/Map'
import View from 'ol/View'
import TileLayer from 'ol/layer/Tile'
import Feature from 'ol/Feature'
import Point from 'ol/geom/Point'
import VectorSource from 'ol/source/Vector'
import VectorLayer from 'ol/layer/Vector'
import OSM from 'ol/source/OSM'
import { Style, Icon, Text, Fill, Stroke } from 'ol/style'
import { fromLonLat, transformExtent } from 'ol/proj'
import { useStore } from 'vuex'
import { boundingExtent } from 'ol/extent'
import { debounce } from 'lodash'
import LeafletMap from '@/components/map/leafletMap.vue'
import bookingRepository from '@/repositories/bookings/bookingRepository'
import LineString from 'ol/geom/LineString'

const ICON_LATEST = 'map-icon-current'
const ICON_FROM = 'map-icon-from'
const ICON_TO = 'map-icon-to'
const ICON_WARNING = 'map-icon-warning'

const store = useStore()
const stopRoute = store.getters['stopStore/stopRoute']
const booking = store.getters['bookingStore/booking']

const map = ref<Map | undefined>(undefined)
const allCoordinates = ref<[number, number][]>([])

onMounted(() => {
  fetchSafeRoute(booking.currentStop.fromAddress, booking.currentStop.toAddress)
  map.value = new Map({
    target: 'map',
    layers: [
      new TileLayer({
        source: new OSM()
      })
    ],
    view: new View({
      center: fromLonLat([10.2039, 56.1629]), // Default DK
      zoom: 6,
      projection: 'EPSG:3857'
    }),
    controls: []
  })

  addMarker(stopRoute.latitude, stopRoute.longitude, ICON_LATEST)
  if (booking.currentStop.fromAddress > 0) {
    addMarker(booking.currentStop.fromLat, booking.currentStop.fromLong, ICON_FROM)
  }

  if (booking.currentStop.toAddress > 0) {
    addMarker(booking.currentStop.toLat, booking.currentStop.toLong, ICON_TO)
  }
})

const fitMapToCoordinates = debounce(() => {
  if (allCoordinates.value.length > 0) {
    const extent = boundingExtent(allCoordinates.value)
    const transformedExtent = transformExtent(extent, 'EPSG:4326', 'EPSG:3857')
    if (map.value) {
      map.value.getView().fit(transformedExtent, {
        padding: [50, 50, 50, 50], // Optional padding
        maxZoom: 15, // Optional max zoom level
        duration: 1000 // Smooth transition
      })
    }
  }
}, 500)

const addCoordinates = (lat: number, lon: number) => {
  allCoordinates.value.push([lon, lat])
}

watchEffect(() => {
  fitMapToCoordinates()
})

async function fetchSafeRoute (from: number, to: number) {
  try {
    const response = await bookingRepository.getSafeRoute(from, to)
    const routeCoords: [number, number][] = response.data.map((point: { latitude: number; longitude: number }) => [point.latitude, point.longitude])
    drawRoute(routeCoords)
  } catch (error) {
    console.error('Failed to fetch safe route:', error)
  }
}

function addMarker (lat: number, lon: number, iconName: string): void {
  const iconFeature = new Feature({
    geometry: new Point(fromLonLat([lon, lat]))
  })

  const iconStyle = new Style({
    image: new Icon({
      anchor: [0.5, 1],
      src: `icons/${iconName}.svg`,
      scale: 1
    })
  })

  iconFeature.setStyle(iconStyle)

  const vectorSource = new VectorSource({
    features: [iconFeature]
  })

  const vectorLayer = new VectorLayer({
    source: vectorSource
  })

  if (map.value) {
    map.value.addLayer(vectorLayer)
  }
  addCoordinates(lat, lon)
}

function drawRoute (coords: [number, number][]): void {
  const routeCoords = coords.map(coord => fromLonLat([coord[1], coord[0]]))
  const routeGeometry = new LineString(routeCoords)
  const routeFeature = new Feature(routeGeometry)
  const routeStyle = new Style({
    stroke: new Stroke({
      color: 'green',
      width: 7
    })
  })

  routeFeature.setStyle(routeStyle)

  const vectorSource = new VectorSource({
    features: [routeFeature]
  })

  const vectorLayer = new VectorLayer({
    source: vectorSource
  })

  if (map.value) {
    map.value.addLayer(vectorLayer)
  }
  coords.forEach(coord => addCoordinates(coord[0], coord[1]))
}

</script>
