import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import dashboardView from '../views/dashboardView.vue'
import loginView from '../views/loginView.vue'
import bookingListView from '../views/bookings/bookingListView.vue'
import bookingView from '../views/bookings/bookingView.vue'
import mapComponent from '@/components/map/mapComponent.vue'
import applicationUserManager from '../core/applicationusermanager'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'dashboardView',
    component: dashboardView,
    meta: { requiresAuth: true }
  },
  {
    path: '/bookings',
    name: 'bookingListView',
    component: bookingListView,
    meta: { requiresAuth: true }
  },
  {
    path: '/bookings/:id',
    name: 'bookingView',
    component: bookingView,
    meta: { requiresAuth: true }
  },
  {
    path: '/login',
    name: 'login',
    component: loginView
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  const isAuthenticated = await applicationUserManager.isAuthenticated()
  if (requiresAuth && !isAuthenticated) {
    next('/login') // Omdiriger til login, hvis brugeren ikke er autentificeret.
  } else {
    next() // tillad navigation
  }
})

export default router
