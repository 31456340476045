<template>
  <div class="relative z-50 w-full h-full" aria-labelledby="slide-over-title" role="dialog" aria-modal="true">
    <transition name="fade" enter-active-class="ease-in-out duration-500" leave-active-class="ease-in-out duration-500"
      enter-from-class="opacity-0" enter-to-class="opacity-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
      <div v-if="isVisible" class="fixed inset-0 bg-gray-700 bg-opacity-80 transition-opacity"></div>
    </transition>
    <transition name="slide" enter-active-class="transform transition ease-in-out duration-500 sm:duration-700"
      enter-from-class="translate-x-full" enter-to-class="translate-x-0"
      leave-active-class="transform transition ease-in-out duration-500 sm:duration-700" leave-from-class="translate-x-0"
      leave-to-class="translate-x-full">
      <div v-if="isVisible" class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
            <div class="pointer-events-auto relative w-screen max-w-md">
              <div class="absolute left-0 top-0 -ml-8 flex pr-2 pt-4 sm:-ml-10 sm:pr-4">
                <button @click="close()" type="button"
                  class="relative rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white">
                  <span class="absolute -inset-2.5"></span>
                  <span class="sr-only">Close panel</span>
                  <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
                    aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>

              <div class="flex h-full flex-col overflow-y-scroll bg-gray-100 py-6 shadow-xl">
                <div class="flex flex-col gap-4 px-6 sm:px-6">
                  <div>
                    <div class="flex grow flex-col">
                      <div class="text-sm text-gray-400">Booking</div>
                      <div class="text-base font-medium leading-5 text-gray-800">
                        {{ details.bookingTitle }}</div>
                    </div>
                  </div>
                </div>

                <div class="relative mt-6 flex-1 bg-white px-4 sm:px-6">
                  <div class="flex items-center mb-4">
                    <div class="grow">
                      <h1 class="text-xl font-medium text-primary">Handling</h1>
                    </div>
                  </div>
                  <div class="relative my-3">
                    <select id="transport_type"
                      class="block w-full appearance-none text-sm font-medium rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-200 focus:ring-2 focus:ring-inset focus:ring-secondary sm:text-sm sm:leading-6"
                      aria-labelledby="transport_type" v-model="description">
                      <option value="fejning">fejning</option>
                      <option value="vask">vask</option>
                      <option value="ingen">ingen</option>
                    </select>
                    <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-400">
                      <svg class="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M5.29289 7.29289C5.68342 6.90237 6.31658 6.90237 6.7071 7.29289L9.99999 10.5858L13.2929 7.29289C13.6834 6.90237 14.3166 6.90237 14.7071 7.29289C15.0976 7.68342 15.0976 8.31658 14.7071 8.70711L10.7071 12.7071C10.3166 13.0976 9.68341 13.0976 9.29289 12.7071L5.29289 8.70711C4.90237 8.31658 4.90237 7.68342 5.29289 7.29289Z"
                          fill="#6B7280" />
                      </svg>
                    </div>
                  </div>

                  <div class="flex items-center mb-4" v-if="description === 'vask'">
                    <div class="grow">
                      <h1 class="text-xl font-medium text-primary">Vask type</h1>
                    </div>
                  </div>
                  <div class="relative my-3" v-if="description === 'vask'">
                    <select id="transport_type"
                      class="block w-full appearance-none text-sm font-medium rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-200 focus:ring-2 focus:ring-inset focus:ring-secondary sm:text-sm sm:leading-6"
                      aria-labelledby="transport_type" v-model="washType">
                      <option value="alm">alm</option>
                      <option value="enzym">enzym</option>
                      <option value="ren">ren</option>
                    </select>
                    <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-400">
                      <svg class="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M5.29289 7.29289C5.68342 6.90237 6.31658 6.90237 6.7071 7.29289L9.99999 10.5858L13.2929 7.29289C13.6834 6.90237 14.3166 6.90237 14.7071 7.29289C15.0976 7.68342 15.0976 8.31658 14.7071 8.70711L10.7071 12.7071C10.3166 13.0976 9.68341 13.0976 9.29289 12.7071L5.29289 8.70711C4.90237 8.31658 4.90237 7.68342 5.29289 7.29289Z"
                          fill="#6B7280" />
                      </svg>
                    </div>
                  </div>

                  <div class="px-2">
                    <div class="py-4">
                      <button type="submit" @click="addWash()"
                        class="flex w-full justify-center rounded-md bg-blue-400 px-3 py-2 font-medium leading-6 text-white shadow-sm hover:bg-gray-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-secondary"
                        :class="{'opacity-20 pointer-events-none' : canWash}">
                        Registrer {{ description }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup lang="ts">
import bookingRepository from '@/repositories/bookings/bookingRepository'
import { computed, onMounted, onUnmounted, ref, watch, watchEffect } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'

const store = useStore()
const route = useRoute()
const id = route.params.id

const props = defineProps({
  isVisible: Boolean
})

const close = () => {
  store.dispatch('bookingStore/toggleWashOpen')
}

const details = computed(() => store.getters['bookingStore/booking'])

const canWash = computed(() => {
  if (!details.value) {
    return
  }
  const value = details.value.stops.filter(s => s.stopDone === false).length > 0
  return value
})

const description = ref('fejning')
const washType = ref('alm')

const addWash = async () => {
  try {
    const response = await bookingRepository.addWash(id, { bookingId: id, description: description.value, washType: washType.value, time: new Date(), user: '-' })
    if (response.data) {
      store.commit('bookingStore/setWash', response.data)
      store.dispatch('bookingStore/toggleWashOpen')
    }
  } catch (error) {
    console.log('error toggling stop', error)
  }
}

</script>
