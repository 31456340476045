import axios from 'axios'
import applicationUserManager from '../core/applicationusermanager'
import { apiConfig } from './apiConfig'
import { useRoute } from 'vue-router'

const baseDomain = `${apiConfig.url}`
const baseURL = `${baseDomain}`

const axiosClient = axios.create({
  baseURL,
  headers: {
    crossorigin: 'true',
    'Content-Type': 'application/json'
  }
})

axiosClient.interceptors.request.use(req => {
  const token = applicationUserManager.getAccessToken()
  if (token) {
    req.headers.authorization = `Bearer ${token}`
  }
  return req
})

axios.interceptors.response.use(
  (response) => {
    console.log('401 Unauthorized')
    return response
  }, (error) => {
    if (error.response?.status === 401) {
      console.log('401 Unauthorized')
    }

    return Promise.reject(error)
  }
)
export default axiosClient
