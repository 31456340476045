import stopRepository from '@/repositories/bookings/stopRepository'
import apiState from '@/core/enums/apiStates'
import { Commit } from 'vuex'

const stopStore = {
  namespaced: true,
  state: {
    stopRoute: {},
    stopRouteState: apiState.INIT,
    detailsOpen: false,
    details: {},
    serviceFunctionsOpen: false,
    serviceFunctionType: null,
  },
  getters: {
    stopRouteState (state: any) {
      return state.stopRouteState
    },
    stopRoute (state: any) {
      return state.stopRoute
    },
    detailsOpen (state: any) {
      return state.detailsOpen
    },
    getDetails (state: any) {
      return state.details
    },
    serviceFunctionsOpen (state: any) {
      return state.serviceFunctionsOpen
    },
    serviceFunctionType (state: any) {
      return state.serviceFunctionType
    }
  },
  mutations: {
    setStopRouteState: (state: any, apiState: any) => {
      state.stopRouteState = apiState
    },
    setStopRoute: (state: any, response: any) => {
      console.log('setting stop route', response)
      const { data } = response
      state.stopRoute = data
      state.stopRouteState = apiState.LOADED
    },
    setDetails (state: any, details: any) {
      console.log('setting details to: ', details)
      state.details = details
      console.log('details state is: ', state.details)
    },
    toggleDetailsOpen (state: any) {
      state.detailsOpen = !state.detailsOpen
    },
    toggleServiceFunctions (state: any) {
      state.serviceFunctionsOpen = !state.serviceFunctionsOpen
    },
    setServiceFunctionType (state: any, type: string) {
      state.serviceFunctionType = type
    }
  },
  actions: {
    async getStopRouteStatus ({ commit }: { commit: Commit }, dto: any) {
      commit('setStopRouteState', apiState.LOADING)
      try {
        const response = await stopRepository.getStopRouteStatus(dto.id, dto.toAddress)
        commit('setStopRoute', response)
        console.log('getStopRouteStatus', response)
      } catch (error) {
        commit('setStopRouteState', apiState.ERROR)
      }
    },
    async showStopDetails ({ commit }: { commit: Commit}, stopDetails: any) {
      console.log('setting stop to: ', stopDetails)
      commit('setDetails', stopDetails)
      commit('toggleDetailsOpen')
    },
    async toggleDetails ({ commit }: { commit: Commit}) {
      commit('toggleDetailsOpen')
    }
  },
  modules: {
  }
}

export default stopStore
