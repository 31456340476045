<template>
  <div>
    <section class="flex flex-col bg-gray-50 h-screen">
      <headerComponent></headerComponent>
      <section class="flex flex-col mt-16">
        <div class="px-6 py-5 text-sm text-gray-400">
          <h1 class="text-xl font-medium text-secondary">Dashboard</h1>
        </div>
        <ul class="space-y-4">
          <listItemComponent :route="'bookings'" :title="'Tur liste'" :subTitle="'Dine allokerede ture'"></listItemComponent>
          <!-- <listItemComponent :route="'/'" :title="'Tidsregistrering'" :subTitle="'Arbejdstid og plan'"></listItemComponent>
          <listItemComponent :route="'/'" :title="'Dokumenter'" :subTitle="'Dokumentation ???'"></listItemComponent>
          <listItemComponent :route="'/'" :title="'Beskeder'" :subTitle="'Beskeder vigtige for din hverdag'" :messages="8"></listItemComponent> -->
        </ul>
      </section>
    </section>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import headerComponent from '../components/headerComponent.vue'
import listItemComponent from '../components/listItemComponent.vue'

export default defineComponent({
  name: 'dashboardView',
  components: {
    headerComponent,
    listItemComponent
  }
})
</script>
