import axiosClient from '@/core/axiosClient'
const resource = '/api/booking'

export default {
  async getBookingList () {
    return await axiosClient.get(`${resource}/getAll`)
  },
  async getBooking (id: number) {
    return await axiosClient.get(`${resource}/getById/${id}`)
  },
  async getBookingRoute (from: number, to: number) {
    return await axiosClient.get(`${resource}/getBookingRoute/?from=${from}&to=${to}`)
  },
  async getSafeRoute (from: number, to: number) {
    return await axiosClient.get(`${resource}/saferoute/${from}/${to}`)
  },
  async getSystemLocations () {
    return await axiosClient.get(`${resource}/systemlocations`)
  },
  async getBookingDriverLog (bookingId: number) {
    return await axiosClient.get(`${resource}/driverlog/${bookingId}`)
  },
  async saveManualLog (dto: any) {
    return await axiosClient.post(`${resource}/driverlog`, dto)
  },
  async getDriverLogById (id: number, bookingId: number) {
    return await axiosClient.get(`${resource}/driverlog/get/${id}/${bookingId}`)
  },
  async deleteManualLog (id: number, bookingId: number) {
    return await axiosClient.delete(`${resource}/driverlog/${id}/${bookingId}`)
  },
  async startDefrost (bookingId: number) {
    return await axiosClient.post(`${resource}/defrost/start/${bookingId}`)
  },
  async endDefrost (dto: any) {
    return await axiosClient.post(`${resource}/defrost/end`, dto)
  },
  async getDefrostByBookingId (bookingId: number) {
    return await axiosClient.get(`${resource}/defrost/get/${bookingId}`)
  },
  async addWash (bookingId: any, dto: any) {
    return await axiosClient.post(`${resource}/wash/${bookingId}`, dto)
  },
  async pauseTemperature (bookingId: any, dto: any) {
    return await axiosClient.post(`${resource}/pause/${bookingId}`, dto)
  },
  async resumeTemperature (bookingId: any, dto: any) {
    return await axiosClient.post(`${resource}/resume/${bookingId}`, dto)
  },
  async cancelBooking (bookingId: any, dto: any) {
    return await axiosClient.post(`${resource}/cancel/${bookingId}`, dto)
  },
  async stopBooking (bookingId: any, dto: any) {
    return await axiosClient.post(`${resource}/stop/${bookingId}`, dto)
  },
  async addImage (bookingId: any, dto: any) {
    return await axiosClient.post(`${resource}/image/${bookingId}`, dto)
  },
  async getAvailableTrailers() {
    return await axiosClient.get(`${resource}/available-trailers`)
  },
  async changeTrailerForDriver(dto: any) {
    return await axiosClient.post(`${resource}/change-trailer`, dto)
  },
  async getDeviationReasons() {
    return await axiosClient.get(`${resource}/deviation-reasons`)
  },
  async getActiveDeviation(bookingId: number) {
    return await axiosClient.get(`${resource}/active-deviation/${bookingId}`)
  },
  async beginDeviation(bookingId: number, deviationReasonId: number, startTime: Date, deviationSpecificationId?: number) {
    return await axiosClient.post(`${resource}/begin-deviation`, { bookingId, deviationReasonId, deviationSpecificationId, startTime })
  },
  async endDeviation(deviationId: number, endTime: Date) {
    return await axiosClient.post(`${resource}/end-deviation/`, { deviationId, endTime })
  }
}
