import axiosClient from '@/core/axiosClient'
const resource = '/api/stop'

export default {
  async getStopRouteStatus (id: number, toAddress: number) {
    return await axiosClient.get(`${resource}/getStopRouteStatus/${id}?toAddress=${toAddress}`)
  },
  async updateStopAddress (stopId: number, time: Date, locationId: number) {
    return await axiosClient.put(`${resource}/updateStopAddress/${locationId}`, { time, locationId, stopId, user: '-' })
  },
  async startStop (stopId: number, time: Date, stopType: string) {
    return await axiosClient.post(`${resource}/startStop/${stopId}`, { time, stopType, stopId, user: '-' })
  },
  async endStop (stopId: number, time: Date, updatedQuantity: number) {
    return await axiosClient.post(`${resource}/endStop/${stopId}/${updatedQuantity}`, { time, updatedQuantity, stopId, user: '-' })
  },
  async markAsNoCargo (stopId: number, time: Date) {
    return await axiosClient.post(`${resource}/noCargo/${stopId}`, { time, stopId, user: '-' })
  },
  async removeStop (stopId: number) {
    return await axiosClient.post(`${resource}/removeStop/${stopId}`)
  }
}
