import bookingRepository from '@/repositories/bookings/bookingRepository'
import { Commit } from 'vuex'

const defrostStore = {
  namespaced: true,
  state: {
    toggleOpen: false,
    defrost: {},
    finished: false
  },
  getters: {
    isOpen (state: any) {
      return state.toggleOpen
    },
    defrost (state: any) {
      return state.defrost
    }
  },
  mutations: {
    toggleOpen (state: any) {
      state.toggleOpen = !state.toggleOpen
    },
    setDefrost (state: any, response: any) {
      state.defrost = response
      console.log('Defrost state set to: ', state.defrost)
    },
    setFinished (state: any, response: any) {
      state.finished = response
    }
  },
  actions: {
    async startDefrost ({ commit }: { commit: Commit }, bookingId: number) {
      try {
        const response = await bookingRepository.startDefrost(bookingId)
        if (response && response.data) {
          commit('setDefrost', response.data)
        }
      } catch (error) {
        console.log(error)
      }
    },
    async endDefrost ({ commit }: { commit: Commit }, dto: any) {
      try {
        const response = await bookingRepository.endDefrost(dto)
        if (response && response.data) {
          commit('setDefrost', response.data)
          commit('toggleOpen')
        }
      } catch (error) {
        console.log(error)
      }
    },
    async getDefrost ({ commit }: { commit: Commit }, bookingId: number) {
      try {
        const response = await bookingRepository.getDefrostByBookingId(bookingId)
        console.log('getDefrost response', response)
        if (response && response.data) {
          commit('setDefrost', response.data)
        } else {
          commit('setDefrost', null) // No existing defrost
        }
      } catch (error) {
        console.log(error)
      }
    }
  }
}

export default defrostStore
